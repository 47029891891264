import { GetListParams } from '@core/interfaces/get-list.interface';
import { LocationFilter } from '@core/interfaces/time-window.interface';

export class GetListRouteSheets {
  static readonly type = '[GetListRouteSheets] Get List Route Sheets';

  constructor(public params: GetListParams<LocationFilter>) {}
}

export class GetRouteSheet {
  static readonly type = '[GetRouteSheet] Get Route Sheet';

  constructor(public id: string) {}
}
