import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { FetchPolicy } from '@core/enums/fetch-policy-apollo.enum';
import { GetListParams } from '@core/interfaces/get-list.interface';
import { LocationFilter } from '@core/interfaces/location-filter.interface';
import { RouteSheetGql, RouteSheetListGql } from '@core/interfaces/route-sheets.interface';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouteSheetsService {
  constructor(private apollo: Apollo) {}

  public getRouteLists(params: GetListParams<LocationFilter>): Observable<ApolloQueryResult<RouteSheetListGql>> {
    return this.apollo.query<RouteSheetListGql>({
      query: gql`
        query getListRouteList($filter: RouteListFilterDto, $pagination: OffsetPaginationInput) {
          getListRouteList(filter: $filter, pagination: $pagination) {
            result {
              id
              startDate
              endDate
              createdAt
              status
              isAvailable
              deliveryActions {
                executionOrder
                orderExternalId
                orderId
                orderInternalId
                orderStatus
                plannedDate
                recipientAddress
                recipientPhone
              }
              updatedAt
              courier {
                id
                name
                avatar
              }
            }
            total
          }
        }
      `,
      variables: {
        filter: params.filter,
        pagination: params.pagination,
      },
      fetchPolicy: FetchPolicy.NoCache,
    });
  }

  public getRouteList(id: number): Observable<ApolloQueryResult<RouteSheetGql>> {
    return this.apollo.query({
      query: gql`
        query GetRouteList($id: Float!) {
          getRouteList(id: $id) {
            id
            startDate
            endDate
            createdAt
            status
            isAvailable
            estimatedTime
            deliveryActions {
              executionOrder
              orderExternalId
              orderId
              orderInternalId
              orderStatus
              plannedDate
              recipientAddress
              recipientPhone
            }
            orders {
              id
              status
              createdAt
              recipientAddress
              recipientPhone
              deliveryDate
              status
            }
            extendedWaypoints {
              address
              point
              pointOnRoad
              order
            }
            remainingTime
            route {
              coordinates
            }
            status
            updatedAt
            zone {
              id
              name
              polygon
              color
            }
            courier {
              id
              name
              avatar
            }
            waypoints {
              actions {
                createdAt
                id
                type
                updatedAt
              }
              startDate
              endDate
              order
              point
              pointOnRoad
              type
              updatedAt
              id
            }
          }
        }
      `,
      variables: { id },
      fetchPolicy: FetchPolicy.NoCache,
    });
  }
}
