import { Injectable } from '@angular/core';
import { RouteSheetsService } from '@core/services/route-sheets.service';
import {
  Action, Selector, State, StateContext,
} from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { RouteSheetGql, RouteSheetList, RouteSheetListGql } from '@core/interfaces/route-sheets.interface';
import { ApolloQueryResult } from '@apollo/client/core';
import { GetListRouteSheets, GetRouteSheet } from '../action/route-sheets.action';

export interface RouteSheetsStateModel {
  routeSheets: RouteSheetList[];
  routeSheet: RouteSheetList | null;
  total: number | null;
  error: string | null;
}

@State<RouteSheetsStateModel>({
  name: 'RouteSheetsState',
  defaults: {
    routeSheets: [],
    routeSheet: null,
    total: null,
    error: null,
  },
})
@Injectable()
export class RouteSheetsState {
  constructor(private routeSheetsService: RouteSheetsService) {}

  @Selector()
  static getRouteSheets(state: RouteSheetsStateModel): RouteSheetList[] {
    return state.routeSheets;
  }

  @Selector()
  static getRouteSheet(state: RouteSheetsStateModel): RouteSheetList | null {
    return state.routeSheet;
  }

  @Selector()
  static getRouteSheetsTotal(state: RouteSheetsStateModel): number | null {
    return state.total;
  }

  @Action(GetListRouteSheets)
  public getListRouteSheets(
    ctx: StateContext<RouteSheetsStateModel>,
    { params }: GetListRouteSheets,
  ): Observable<ApolloQueryResult<RouteSheetListGql>> {
    return this.routeSheetsService.getRouteLists(params).pipe(
      tap((res) => {
        if (res && res.data) {
          ctx.patchState({ routeSheets: res.data.getListRouteList.result });
          ctx.patchState({ total: res.data.getListRouteList.total });
        }
      }),
    );
  }

  @Action(GetRouteSheet)
  public getRouteSheet(
    ctx: StateContext<RouteSheetsStateModel>,
    { id }: GetRouteSheet,
  ): Observable<ApolloQueryResult<RouteSheetGql>> {
    return this.routeSheetsService.getRouteList(Number(id)).pipe(
      tap((res) => {
        if (res && res.data) {
          ctx.patchState({ routeSheet: res.data.getRouteList });
        }
      }),
    );
  }
}
